<template>
    <div>
        <notifications></notifications>
        <base-nav
            v-model="showMenu"
            type="success"
            menu-classes="justify-content-end"
            class="navbar-horizontal navbar-main"
            expand="lg"
            @change="handleNavBarEvent"
            :show="showMenu"
        >
            <div slot="brand" class="navbar-wrapper">
                <b-navbar-brand
                    :to="{ path: '/login', query: { next: this.next } }"
                    v-if="!logged_in"
                >
                    <img src="img/brand/logos/otingaWhite.svg" />
                </b-navbar-brand>
            </div>

            <template>
                <div class="navbar-collapse-header">
                    <b-row>
                        <b-col cols="6" class="collapse-brand">
                            <router-link to="/">
                                <img src="img/brand/logos/otinga.svg" />
                            </router-link>
                        </b-col>
                        <b-col cols="6" class="collapse-close">
                            <button
                                type="button"
                                class="navbar-toggler"
                                @click="showMenu = !showMenu"
                            >
                                <span></span>
                                <span></span>
                            </button>
                        </b-col>
                    </b-row>
                </div>

                <b-navbar-nav class="mr-auto">
                    <b-nav-item
                        :to="{ path: '/login', query: { next: this.next } }"
                        v-if="!logged_in"
                    >
                        <span :class="navItemClasses()">Login</span>
                    </b-nav-item>
                    <b-nav-item
                        :to="{ path: '/register', query: { next: this.next } }"
                        v-if="!logged_in"
                    >
                        <span :class="navItemClasses()">Register</span>
                    </b-nav-item>
                    <b-nav-item to="/logout" v-if="logged_in">
                        <span :class="navItemClasses()">Log Out</span>
                    </b-nav-item>
                </b-navbar-nav>
                <hr class="d-lg-none" />
                <b-navbar-nav class="align-items-lg-center ml-lg-auto">
                    <!-- <b-nav-item link-classes="nav-link-icon" href="https://www.facebook.com/" target="_blank"
            data-toggle="tooltip" data-original-title="Like us on Facebook" >
           <i class="fab fa-facebook-square"></i>
           <span class="nav-link-inner--text d-lg-none">Facebook</span>
       </b-nav-item>
       <b-nav-item link-classes="nav-link-icon" href="https://www.instagram.com/" target="_blank" data-toggle="tooltip" data-original-title="Follow us on Instagram">
           <i class="fab fa-instagram"></i>
           <span class="nav-link-inner--text d-lg-none">Instagram</span>
       </b-nav-item>
       <b-nav-item link-classes="nav-link-icon" href="https://twitter.com/" target="_blank" data-toggle="tooltip" data-original-title="Follow us on Twitter">
           <i class="fab fa-twitter-square"></i>
           <span class="nav-link-inner--text d-lg-none">Twitter</span>
       </b-nav-item>
       <b-nav-item link-classes="nav-link-icon" href="https://github.com/" target="_blank" data-toggle="tooltip" data-original-title="Star us on Github">
           <i class="fab fa-github"></i>
           <span class="nav-link-inner--text d-lg-none">Github</span>
       </b-nav-item> -->
                    <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
                    <!-- <li class="nav-item d-none d-lg-block ml-lg-4">
         <a href="https://otinga.io/product/bootstrap-vue-argon-dashboard-pro?ref=bvadp-index-page" target="_blank"
            class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fas fa-shopping-cart mr-2"></i>
            </span>
           <span class="nav-link-inner--text">Purchase now</span>
         </a>
       </li> -->
                </b-navbar-nav>
            </template>
        </base-nav>

        <div class="main-content">
            <zoom-center-transition
                :duration="pageTransitionDuration"
                mode="out-in"
            >
                <router-view
                    class="router-view-content"
                    @updatedLoggedInStatus="updateLoggedInStatus"
                ></router-view>
            </zoom-center-transition>
        </div>

        <footer class="py-5" id="footer-main">
            <b-container>
                <b-row align-v="center" class="justify-content-xl-between">
                    <b-col xl="6">
                        <div
                            class="copyright text-center text-xl-left text-muted"
                        >
                            © {{ year }}
                            <a
                                href=""
                                class="font-weight-bold ml-1"
                                target="_blank"
                                >Otinga</a
                            >
                        </div>
                    </b-col>
                    <b-col xl="6" class="col-xl-6">
                        <b-nav
                            class="nav-footer justify-content-center justify-content-xl-end"
                        >
                            <!-- <b-nav-item href="" target="_blank">
                                Otinga
                            </b-nav-item>
                            <b-nav-item href="" target="_blank">
                                About Us
                            </b-nav-item> -->
                            <b-nav-item
                                :to="{
                                    name: 'PrivacyPolicy',
                                }"
                            >
                                Privacy Policy
                            </b-nav-item>
                            <b-nav-item
                                :to="{
                                    name: 'TermsAndConditions',
                                }"
                            >
                                Terms and Conditions
                            </b-nav-item>
                            <b-nav-item
                                href="https://otinga.freshdesk.com/support/tickets/new"
                                target="_blank"
                            >
                                Support
                            </b-nav-item>
                        </b-nav>
                    </b-col>
                </b-row>
            </b-container>
        </footer>
    </div>
</template>
<script>
import { BaseNav } from "@/components";
import { ZoomCenterTransition } from "vue2-transitions";
import store from "@/store";

export default {
    components: {
        BaseNav,
        ZoomCenterTransition,
    },
    props: {
        backgroundColor: {
            type: String,
            default: "black",
        },
    },
    data() {
        return {
            showMenu: false,
            menuTransitionDuration: 250,
            pageTransitionDuration: 200,
            year: new Date().getFullYear(),
            pageClass: "login-page",
            logged_in: store.store.getters.isLogin,
            next: null,
        };
    },
    computed: {
        title() {
            return `${this.$route.name} Page`;
        },
    },
    methods: {
        get_next() {
            this.next = this.$route.query.next;
        },
        updateLoggedInStatus() {
            this.logged_in = store.store.getters.isLogin;
        },
        pollLoggedInStatus() {
            setInterval(() => {
                this.logged_in = store.store.getters.isLogin;
            }, 500);
        },
        navItemClasses() {
            if (this.showMenu) {
                return "text-white nav-link-inner--text";
            } else {
                return " text-white nav-link-inner--text";
            }
        },

        closeMenu() {
            document.body.classList.remove("nav-open");
            this.showMenu = false;
        },
        openMenu() {
            document.body.classList.add("nav-open");
            this.showMenu = true;
        },
        handleNavBarEvent(state) {
            if (state) {
                this.openMenu();
            } else {
                this.closeMenu();
            }
        },
        setBackgroundColor() {
            document.body.classList.add("bg-default");
        },
        removeBackgroundColor() {
            document.body.classList.remove("bg-default");
        },
        updateBackground() {
            if (!this.$route.meta.noBodyBackground) {
                this.setBackgroundColor();
            } else {
                this.removeBackgroundColor();
            }
        },
    },
    created() {
        this.pollLoggedInStatus();
    },
    mounted() {
        this.get_next();
    },
    beforeDestroy() {
        this.removeBackgroundColor();
    },
    beforeRouteUpdate(to, from, next) {
        // Close the mobile menu first then transition to next page
        if (this.showMenu) {
            this.closeMenu();
            setTimeout(() => {
                next();
            }, this.menuTransitionDuration);
        } else {
            next();
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                this.updateBackground();
                this.get_next();
            },
        },
    },
};
</script>
<style lang="scss">
$scaleSize: 1;
@keyframes zoomIn8 {
    from {
        opacity: 0.8;
        // Too busy visually
        // transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
        opacity: 1;
    }
}

.main-content .zoomIn {
    animation-name: zoomIn8;
}

@keyframes zoomOut8 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.8;
        // Too busy visually
        // transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-content .zoomOut {
    animation-name: zoomOut8;
}
.router-view-content {
    min-height: 75vh;
}
</style>
