import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);
// configure router


function get_un_auth_routes(){
    let un_auth_routes = []

    routes.forEach(route => {
        if ('children' in route && route.children.length > 0 ){
            route.children.forEach(child_route => {
                if ('un_auth' in child_route && child_route.un_auth === true && 'name' in child_route){
                    un_auth_routes.push(child_route.name)
                }
            });
        }
    });
    return un_auth_routes; 
}




const router = new VueRouter({
    routes, //short for routes: routes 
    linkActiveClass: "active",
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    // let excludes = [
    //     "Logout",
    //     "Login",
    //     "Register",
    //     "ForgotPassword",
    //     "ResetPassword",
    //     "HandleInvite",
    //     "PrivacyPolicy",
    //     "TermsAndConditions",
    //     "LoginExpired",
    // ];

    let excludes = get_un_auth_routes(); 

    let logged_in = store.store.getters.isLogin;

    // Session Management
    if (!excludes.includes(to.name) && !logged_in) {
        if (to.path == "/login") {
            next({ name: "Login" });
        }

        // Here we handle "special" un-auth flows
        else if (
            "particpant_invite" in to.query &&
            to.query.particpant_invite
        ) {
            next({ name: "Register", query: { next: to.fullPath, particpant_invite:true } });
        } else {
            next({ name: "Login", query: { next: to.fullPath } });
        }
    }
    let logged_in_excluded_pages = ["Register", "Login", "Register"];
    if (logged_in_excluded_pages.includes(to.name) && logged_in) {
        next({ path: "/" });
    }

    // Final Next
    next();
});

function handleActivity() {
    if (!store.store.getters.isActive && store.store.getters.isLogin) {
        store.store.commit("setIsActive", true);
    } else if (!store.store.getters.isLogin) {
        api.user.logout();
    }
    window.removeEventListener("keyup", handleActivity);
    window.removeEventListener("mousemove", handleActivity);
}

export default router;
