export default {
  getBaseUrl(api_suffix) {
    // PRODUCTION
    var base_url = "https://api-app.otinga.io";

    if (process.env.VUE_APP_BACKEND === "prod") {
      base_url = `https://api-app.otinga.io`;
    } else if (process.env.NODE_ENV === "development") {
      base_url = `http://localhost:8000`;
    } else if (window.location.origin.includes("dev")) {
      if (window.location.origin.includes("//dev-app")) {
        base_url = `${window.location.origin.replace(
          "//dev-app",
          "//dev-api-app"
        )}`;
      }
    }
    if (!base_url.includes("https") && process.env.NODE_ENV !== "development") {
      base_url = base_url.replace("http", "https");
    }
    return base_url + api_suffix;
  },
};
