<template>
    <div class="timeline-block" :class="{ 'timeline-inverted': inverted }">
        <slot name="badge">
            <span :class="timeline_step_classes()">
                <i :class="badgeIcon"></i>
            </span>
        </slot>
        <div class="timeline-content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "time-line-item",
    props: {
        inverted: Boolean,
        title: String,
        badgeType: {
            type: String,
            default: "success",
        },
        badgeIcon: {
            type: String,
            default: "",
        },
        dotOnly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        timeline_step_classes() {
            let classes = {
                "timeline-step": true,
                "timeline-step-dot": false,
            };
            classes[`badge-${this.badgeType}`] = true;
            if (this.dotOnly) {
                classes["timeline-step"] = false;
                classes["timeline-step-dot"] = true;
            }
            return classes;
        },
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
</style>
