export default {
    deepcopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },

    sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    },
};
