//Vue Apollo
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { RetryLink } from "apollo-link-retry";
import { ApolloLink, concat, split } from "apollo-link";
import { onError } from "@apollo/client/link/error";
import { logErrorMessages } from "@vue/apollo-util";


import disconnectedNotification from "@/util/disconnectedNotification";

import router from "./routes/router";
//utils
import url_utils from "@/util/url";

/*Vue Apollo configuration */

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: url_utils.getBaseUrl("/u-gql"),
});

// Handling global errors
const errorLink = onError((error) => {
    if (process.env.NODE_ENV !== "production") {
        logErrorMessages(error);
    }
});



// Retries
const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: 20000,
        jitter: true,
    },
    attempts: {
        max: 100,
        retryIf: (error, _operation) => {
            // Catching 400 series might need review TODO: review this.
            if (String(error).includes("status code 40")) {
                return false;
            }
            else if (String(error).includes("Failed to fetch")) {
                disconnectedNotification.send();
                return true; // We want to continually try to refetch. 
            }
            return !!error;
        },
    },
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClientUnAuth = new ApolloClient({
    link: ApolloLink.from([errorLink, retryLink, httpLink]),
    cache,
});
