<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar logo="img/brand/logos/otingaLandscape.svg">
      <template slot="links">
        <!-- <sidebar-item
                    :link="{
                        name: 'Ideas',
                        icon: 'fas fa-lightbulb  text-gray',
                    }"
                >
                    <sidebar-item :link="{ name: 'Discover', path: '/' }" />
                    <sidebar-item :link="{ name: 'My Ideas', path: '/' }" />
                </sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'Hackathons',
            icon: 'fas fa-lightbulb  text-gray',
            path: '/hackathon',
          }"
        >
          <template
            #rawcontent
            v-if="$apollo.queries.get_active_hackathon.loading"
          >
            <i class="fas fa-spinner fa-spin text-gray ml-4"></i>
          </template>
          <sidebar-item
            :link="{
              name: 'Discover',
              path: '/hackathon/discover',
            }"
          />
          <!-- <sidebar-item
                        :link="{
                            name: 'Manage Hackathons',
                            path: '/hackathon/manage',
                        }"
                    /> -->
          <sidebar-item
            :link="{
              name: 'My Hackathons',
              path: '/hackathon/mine',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Hackathon Profile',
              path: '/hackathon/profile',
            }"
          />
        </sidebar-item>

        <!-- Hackathon Subsection -->

        <sidebar-item
          v-for="hackathon in validHackathons"
          :key="hackathon.id"
          :link="{
            name: hackathon.name,
            icon: 'fas fa-lightbulb  text-gray',
          }"
        >
          <!-- Home -->
          <sidebar-item
            :link="{
              name: 'Home',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/detail`,
            }"
          >
          </sidebar-item>
          <!-- Organizing Team -->
          <sidebar-item
            v-if="
              hackathon.managementTeamVisible ||
              hackathon.isManager ||
              hackathon.isVolunteer ||
              hackathon.isMentor
            "
            :link="{
              name: 'Organizing Team',
              path: `/hackathon/${encodeURIComponent(
                hackathon.id
              )}/organizing-team`,
            }"
          >
          </sidebar-item>
          <!-- Participants -->
          <sidebar-item
            v-if="
              hackathon.isMentor || hackathon.isCreator || hackathon.isManager
            "
            :link="{
              name: 'Participants',
              path: `/hackathon/${encodeURIComponent(
                hackathon.id
              )}/participants`,
            }"
          >
          </sidebar-item>

          <!-- Team -->

          <sidebar-item
            v-if="
              'myTeam' in hackathon &&
              hackathon.myTeam !== null &&
              'id' in hackathon.myTeam
            "
            :link="{
              name: 'My Team',
              path: `/hackathon/${encodeURIComponent(
                hackathon.id
              )}/team/${encodeURIComponent(hackathon.myTeam.id)}`,
            }"
          />

          <!-- Ideas -->
          <sidebar-item
            :link="{
              name: 'Ideas',
              icon: 'fas fa-lightbulb  text-gray',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Hackathon Ideas',
                path: `/hackathon/${encodeURIComponent(hackathon.id)}/ideas`,
              }"
            />
            <sidebar-item
              :link="{
                name: 'My Ideas',
                path: `/hackathon/${encodeURIComponent(
                  hackathon.id
                )}/ideas/mine`,
              }"
            />
            <sidebar-item
              v-if="hackathon.isManager && hackathon.ideasRequireApproval"
              :link="{
                name: 'Approve Ideas',
                path: `/hackathon/${encodeURIComponent(
                  hackathon.id
                )}/ideas/approve`,
              }"
            />
          </sidebar-item>

          <!-- Schedule -->
          <sidebar-item
            :link="{
              name: 'Schedule',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/schedule`,
            }"
          >
          </sidebar-item>
          <!-- Resources -->
          <sidebar-item
            v-if="hackathon.hasResources"
            :link="{
              name: 'Resources',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/resources`,
            }"
          ></sidebar-item>
          <!-- Mentors -->
          <sidebar-item
            v-if="hackathon.hasMentors"
            :link="{
              name: 'Mentors',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/mentors`,
            }"
          >
          </sidebar-item>
          <!-- Volunteers -->
          <sidebar-item
            v-if="hackathon.hasVolunteers"
            :link="{
              name: 'Heroes',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/heroes`,
            }"
          >
          </sidebar-item>

          <!-- Judging -->
          <sidebar-item
            v-if="hackathon.hasJudges"
            :link="{
              name: 'Judging',
              icon: 'fas fa-lightbulb  text-gray',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Judges',
                path: `/hackathon/${encodeURIComponent(hackathon.id)}/judges`,
              }"
            >
              <!-- <sidebar-item
                                :link="{
                                    name: 'TBC Judge Teams',
                                    path: '/TBC',
                                }"
                            /> -->
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Criteria',
                path: `/hackathon/${encodeURIComponent(
                  hackathon.id
                )}/judging-criteria`,
              }"
            />
            <sidebar-item
              v-if="
                (hackathon.isManager ||
                  hackathon.isJudge ||
                  hackathon.isCreator) &&
                (hackathon.phase == 'JUDGING' || hackathon.phase == 'COMPLETE')
              "
              :link="{
                name: 'Submissions',
                path: `/hackathon/${encodeURIComponent(
                  hackathon.id
                )}/submissions`,
              }"
            />
          </sidebar-item>
          <!-- Prizes -->
          <sidebar-item
            v-if="hackathon.hasPrizes"
            :link="{
              name: 'Prizes',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/prizes`,
            }"
          ></sidebar-item>
          <!-- Goals -->
          <sidebar-item
            v-if="hackathon.isCreator || hackathon.isManager"
            :link="{
              name: 'Goals',
              path: `/hackathon/${encodeURIComponent(hackathon.id)}/goals`,
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Settings',
            icon: 'ni ni-settings text-gray',
          }"
        >
          <sidebar-item :link="{ name: 'Profile', path: '/user/profile' }" />
          <!-- <sidebar-item :link="{ name: 'Account', path: '/' }">
                        <sidebar-item
                            :link="{ name: 'Subscription', path: '/' }"
                        />
                    </sidebar-item> -->

          <sidebar-item
            v-if="organizations.length > 0"
            :link="{ name: 'Organization' }"
          >
            <sidebar-item
              :link="{
                name: 'Members',
                path: `/organization/${encodeURIComponent(
                  active_organization.id
                )}/members`,
              }"
            />
          </sidebar-item>

          <!-- <sidebar-item
                            :link="{
                                name: 'Settings',
                                path: `/organization/${encodeURIComponent(
                                    active_organization.id
                                )}/settings`,
                            }"
                        /> -->
          <!-- </sidebar-item> -->

          <sidebar-item v-if="$apollo.queries.get_user_organization.loading">
            <template #rawcontent>
              <i class="fas fa-spinner fa-spin text-gray ml-5"></i>
            </template>
          </sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view class="router-view-content"></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import SidebarItem from "../../components/SidebarPlugin/SidebarItem.vue";

// Queries
import {
  GET_HACKATHON_SIDEBAR,
  GET_USER_ORGANIZATION,
} from "@/graphql/queries";

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    SidebarItem,
  },
  apollo: {
    get_user_organization: {
      query: GET_USER_ORGANIZATION,
      result(res) {
        this.organizations = [];

        let f_data = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(res.data.allOrganization)
        );
        if (f_data.length > 0) {
          f_data.forEach((el) => {
            this.organizations.push(el);
          });
          this.active_organization.id = this.organizations[0].id;
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_user_organization = data;
      },

      variables() {
        return {
          user_id: store.getters.getUser.id_b64,
        };
      },
    },
    get_active_hackathon: {
      query: GET_HACKATHON_SIDEBAR,
      skip() {
        return !this.activeHackathonId;
      },
      variables() {
        return {
          hackathon_id: this.activeHackathonId,
        };
      },
      result(res) {
        this.hackathons = [];
        if ("hackathonHackathon" in res.data) {
          this.hackathons.push(res.data.hackathonHackathon);
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_active_hackathon = data;
      },
    },
  },
  data() {
    return {
      apollo_data: {
        get_active_hackathon: null,
        get_user_organization: null,
      },
      hackathons: [],
      organizations: [],
      active_organization: {
        id: null,
      },
    };
  },

  computed: {
    activeHackathonId() {
      return store.getters.getActiveHackathon?.id || null;
    },
    validHackathons() {
      return this.hackathons.filter((hackathon) => hackathon && hackathon.id);
    },
  },

  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },

    manage_get_active_hackahton() {
      if (this.activeHackathonId) {
        setTimeout(() => {
          graph_utils.enable_query(this.$apollo.queries.get_active_hackathon);
        }, 50);
      } else {
        setTimeout(() => {
          graph_utils.disable_query(this.$apollo.queries.get_active_hackathon);
        }, 50);
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  created() {
    global_event_emitter.$on("hackathon_sidebar_reload_required", () => {
      setTimeout(() => {
        this.$apollo.queries.get_active_hackathon.refetch();
      }, 500);
    });
    global_event_emitter.$on("sidebar_reload_required", () => {
      setTimeout(() => {
        this.$apollo.queries.get_active_hackathon.refetch();
        this.$apollo.queries.get_user_organization.refetch();
      }, 500);
    });
  },
  beforeDestroy() {
    global_event_emitter.$off("hackathon_sidebar_reload_required");
    global_event_emitter.$off("sidebar_reload_required");
  },

  watch: {
    activeHackathonId: {
      immediate: true,
      handler: function () {
        this.manage_get_active_hackahton();
      },
    },
  },
};
</script>
<style lang="scss">
.router-view-content {
  min-height: 80vh;
}

.el-table .cell {
  white-space: normal;
  word-break: keep-all;
}
</style>
