<template>
  <div class="flex-col items-center">
    <div @mouseleave="is_focused=false">
      <b-input
        type="text"
        v-model="selected"
        @input="filter"
        @focus="inputFocused"
        @click="is_focused=true"
        autocomplete="off"
        placeholder="Enter your skills here"
        style=""
        :required="required"
      ></b-input>
      <validation-provider
              :rules="rules"
              :name="name"
              v-bind="$attrs"
              v-slot="{ errors }"
          >
              <input type="textarea" hidden />
              <div
                  v-if="complete"
                  class="invalid-feedback"
                  style="display: block"
              >
                  {{ errors[0] }}
              </div>
          </validation-provider>
      <div v-if="filtered_select && is_focused" class="box overlay">
        <ul class="dropdown" :pill="true">
          <li
            v-for="item in filtered_select"
            class="py-2 border-b cursor-pointer"
            @click="setSelection(item)"
            :key="item"
            :pill="true"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
export default {
  name: "AutoComplete",
  props: {
    items: {
      type: Array,
      required: true,
    },
    name: String,
    required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)"
      },
      rules: {
          type: [String, Array, Object],
          description: "Vee validate validation rules",
          default: "",
      },
    complete: Boolean,
  },
  components: {
    BaseInput,
  },
  data() {
    return {
      selected: "",
      filtered_select: [],
      filter_val: "",
      is_focused: false,
      errors: [],
    };
  },
  methods: {
    filter() {
      if (this.selected) {
        this.filtered_select = this.items.filter((selected) => {
          return selected.toLowerCase().includes(this.selected.toLowerCase());
        });
      } else {
        this.filtered_select = [];
      }
    },
    setSelection(item) {
      this.selected = item;
      this.is_focused = false;
      //this.filtered_select = [];
      this.$emit("selectionFin", item);
      this.selected = "";
    },
    inputFocused() {
      console.log("test");
      this.is_focused = true;
      if (this.selected) {
        this.filtered_select = this.items.filter((selected) => {
          return selected.toLowerCase().includes(this.selected.toLowerCase());
        });
      } else {
        this.filtered_select = this.items;
      }
    },
  },
};
</script>

<style>
.dropdown {
  width: 96%;
  text-align: center;
  background-color: rgb(223, 223, 223);
  cursor: pointer;
  list-style-type: none;
  align-self: right;
  border-radius: 20px;
  
}
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  
}

.overlay {
  z-index: 9;
  /* margin: 30px; */
}
</style>
